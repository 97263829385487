import React, { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import { Episode, License, Movie, Playable, usePlayable, useProgress, useFavorite, Content } from '@vodafoneis/sjonvarpskjarni-js-lib';
import { SeriesSceneContext } from '../../contexts/SeriesSceneContext';
import { ProgressButton } from '../ProgressButton';
import { UserContext } from '../../contexts/UserContext';
import { PlaybackContext } from '../../contexts/PlaybackContext';
import MoviePlaylistItem from '../../models/MoviePlaylistItem';
import { LicenseButton } from '../LicenseButton';
import { ActionBtn, Button, MainBuyLinkButton } from '../../ui/core/Buttons/Buttons';
import { ContentButtonsContainer, ContentButtonsMessage } from './ContentButtons.styles';
import { ICON_TYPE } from '../IconComponent/IconComponent';

const ProgressButtons: React.FC<{ playable: Playable; playMovie: (params?: { license?: License; pos?: number }) => void; simpleView: boolean }> = ({ playable, playMovie, simpleView }) => {
	const { t } = useTranslation();
	const { progress, position, hasProgress } = useProgress(playable);
	const { playEpisode } = useContext(SeriesSceneContext);

	const playFromPosition = useCallback(
		(e) => {
			e.preventDefault();

			if (playable instanceof Movie || !playEpisode) {
				playMovie({ pos: position });
			}

			if (playable instanceof Episode && playEpisode) {
				playEpisode(playable, position);
			}
		},
		[playEpisode, playMovie, playable, position]
	);

	const playFromBeginning = useCallback(
		(e) => {
			e.preventDefault();

			if (playable instanceof Movie || !playEpisode) {
				playMovie({ pos: 0 });
			}

			if (playable instanceof Episode && playEpisode) {
				playEpisode(playable, 0);
			}
		},
		[playEpisode, playMovie, playable]
	);

	const primarySubtitle = useMemo(() => {
		if (playable instanceof Episode) {
			return playable.episodeSeason > 0
				? `${t('ContentButtons.SeasonAndEpisode', { seriesNumber: playable.episodeSeason, episodeNumber: playable.episodeNumber })}`
				: `${t('ContentButtons.Episode', { episodeNumber: playable.episodeNumber })}`;
		}

		if (playable instanceof Movie && hasProgress) {
			return t('ContentButtons.ContinueWatching');
		}

		return null;
	}, [hasProgress, playable, t]);

	if (!playable) return null;

	if (simpleView) {
		return (
			<>
				<ProgressButton text={t('ContentButtons.Watch')} iconType={ICON_TYPE.PLAY} handleClick={playFromBeginning} />
			</>
		);
	}

	if (hasProgress) {
		return (
			<>
				<ProgressButton text={primarySubtitle} progress={progress} iconType={ICON_TYPE.PLAY} handleClick={playFromPosition} />
				<ProgressButton text={t('ContentButtons.FromBeginning')} iconType={ICON_TYPE.REWIND} handleClick={playFromBeginning} />
			</>
		);
	}

	return (
		<>
			<ProgressButton text={t('ContentButtons.Watch')} iconType={ICON_TYPE.PLAY} handleClick={playFromBeginning} />
		</>
	);
};

const ContentButtonsContent: React.FC<{ content: Content; playable: Playable; simpleView: boolean }> = ({ content, playable, simpleView }) => {
	const router = useRouter();
	const { t } = useTranslation();
	const { isLoggedIn, requestAuthentication } = useContext(UserContext);
	const { startPlaylist, setInitialPlaybackPosition } = useContext(PlaybackContext);
	const { licenses, isWatchable, isPlayable } = usePlayable(playable);
	const { favorite, toggleFavorite } = useFavorite(content);

	const playMovie = useCallback(
		(params?: { license?: License; pos?: number }) => {
			const { license, pos } = params ?? {};
			router.push(`/movie/${playable.id}/play`);
			startPlaylist([new MoviePlaylistItem(playable, license)]);
			if (pos !== undefined) {
				setInitialPlaybackPosition(pos);
			}
		},
		[playable, router, setInitialPlaybackPosition, startPlaylist]
	);

	const openProduct = useCallback(() => {
		if (playable) {
			const product = playable.getProduct();
			if (product) {
				router.push(`/product/${product.id}`);
			}
		}
	}, [playable, router]);

	const redirectToLogin = useCallback(
		(e) => {
			e.preventDefault();
			requestAuthentication(router.asPath);
		},
		[requestAuthentication, router]
	);

	const favoritesButton = useMemo(() => {
		if (favorite === null) return null;

		return <ActionBtn type={favorite ? ICON_TYPE.REMOVE : ICON_TYPE.ADD} handleClick={toggleFavorite} />;
	}, [favorite, toggleFavorite]);

	return useMemo(() => {
		if (!playable || licenses === null || isWatchable === null || isPlayable === null) return null;

		// If user can watch the content.
		if (isWatchable === true) {
			return (
				<>
					<ProgressButtons simpleView={simpleView} playable={playable} playMovie={playMovie} />
					{favoritesButton}
				</>
			);
		}

		// If the content is not watchable for anonymous users.
		if (isWatchable === false && !isLoggedIn) {
			return <MainBuyLinkButton url={'https://stod2.is/askrift/'} text={t('Buttons.BuySubscription')} />;
		}

		// If content is not subscribed.
		if (isWatchable === false && isLoggedIn && playable.isSubscription()) {
			const product = playable.getProduct();

			if (product) {
				return <Button text={t('Buttons.BuySubscription')} handleClick={openProduct} />;
			}

			return <ContentButtonsMessage>{t('Movie.NotSubscribed')}</ContentButtonsMessage>;
		}

		// If content is not playable.
		if (isPlayable === false) {
			return <ContentButtonsMessage>{t('Movie.NotPlayable')}</ContentButtonsMessage>;
		}

		// If content is not available
		if (licenses.length === 0) {
			return <ContentButtonsMessage>{t('Movie.NotAvailable')}</ContentButtonsMessage>;
		}

		// If TVOD/EST without rental.
		return (
			<>
				{licenses.map((license) => (
					<LicenseButton key={license.id} license={license} onClick={playMovie} />
				))}
				{favoritesButton}
			</>
		);
	}, [playable, licenses, isWatchable, isPlayable, isLoggedIn, favoritesButton, playMovie, redirectToLogin, t, openProduct]);
};

export const ContentButtons: React.FC<{ content: Content; playable: Playable; simpleView?: boolean }> = ({ content, playable, simpleView = false }) => {
	return (
		<ContentButtonsContainer>
			<ContentButtonsContent content={content} playable={playable} simpleView={simpleView} />
		</ContentButtonsContainer>
	);
};
