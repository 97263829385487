import styled from 'styled-components';
import { media } from '../../ui/utils/media';

type DotButtonProps = {
	selected: boolean;
};

type CarouselButtonProps = {
	disabled: boolean;
};

export const EmblaDotButton = styled.button<DotButtonProps>`
	background-color: transparent;
	cursor: pointer;
	position: relative;
	padding: 0;
	outline: 0;
	border: 0;
	width: 30px;
	height: 30px;
	margin-right: 7.5px;
	margin-left: 7.5px;
	display: flex;
	align-items: center;
	:after {
		background-color: ${({ theme }) => theme.colors.white};
		opacity: ${(props) => (props.selected ? 1 : 0.2)};
		width: 100%;
		height: 4px;
		border-radius: 2px;
		content: '';
	}
`;

const EmblaButton = styled.button<CarouselButtonProps>`
	outline: 0;
	width: 30px;
	height: 30px;
	background-color: transparent;
	touch-action: manipulation;
	position: absolute;
	z-index: 1;
	top: 50%;
	transform: translateY(-50%);
	border: 0;
	justify-content: center;
	align-items: center;
	fill: ${({ theme }) => theme.colors.white};
	padding: 0;
	cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
	opacity: ${(props) => (props.disabled ? 0 : 1)};
`;

export const NextButton = styled(EmblaButton)<CarouselButtonProps>`
	right: 16px;
	display: none;
	${media.tabletPortraitUp} {
		display: block;
	}
`;

export const PrevButton = styled(EmblaButton)<CarouselButtonProps>`
	left: 16px;
	display: none;
	${media.tabletPortraitUp} {
		display: block;
	}
	svg {
		transform: rotate(180deg);
	}
`;
