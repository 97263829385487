import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { License, LicenseType } from '@vodafoneis/sjonvarpskjarni-js-lib';
import { Button } from '../ui/core/Buttons/Buttons';

type LicenseButtonProps = {
	license: License;
	onClick: ({ license }: { license: License }) => void;
	primary?: boolean;
};

export const LicenseButton: React.FC<LicenseButtonProps> = ({ license, onClick }) => {
	const { t } = useTranslation();

	const title = useMemo(() => {
		const { price, type } = license;

		if ((price?.value ?? 0) === 0) {
			return t('Movie.Watch');
		}

		if (type === LicenseType.TVOD) {
			return t('Movie.Rent');
		}

		if (type === LicenseType.EST) {
			return t('Movie.Buy');
		}

		return null;
	}, [license, t]);

	const subtitle = useMemo(() => {
		const { price } = license;

		if ((price?.value ?? 0) > 0) {
			return t('Movie.Price', { price: price.value });
		}

		return null;
	}, [license, t]);

	const onClickCallback = useCallback(() => {
		onClick({ license });
	}, [license, onClick]);

	return <Button text={`${title} - ${subtitle}`} handleClick={onClickCallback} />;
};
