import React from 'react';
import { useTranslation } from 'react-i18next';
import { FetchPolicy } from '@apollo/client';
import { ModuleType, useProducts } from '@vodafoneis/sjonvarpskjarni-js-lib';
import MediaCarousel from './MediaCarousel';
import useContentGridRenderer from '../hooks/useContentGridRenderer';
import { CONTENT_LIMIT } from '../config/constants';
import { MediaComponentType } from '../models/MediaComponentType';

const { PRODUCT } = MediaComponentType;
const { M4 } = ModuleType;

const queryOptions = {
	fetchPolicy: 'cache-and-network' as FetchPolicy,
};

export default function ProductsCarousel({ productType }) {
	const { t } = useTranslation();

	const renderItem = useContentGridRenderer({ moduleType: M4, mediaComponentType: PRODUCT });

	const { products } = useProducts(productType, CONTENT_LIMIT, queryOptions);

	if (products?.length > 0) {
		return <MediaCarousel id={'carousel-products'} title={t(`Products.${productType}`)} items={products} detailsUrl={null} renderItem={renderItem} />;
	}

	return null;
}
