import React from 'react';
import { ProductType, useContentFromSlug, useFrontpage, useHeroBanner } from '@vodafoneis/sjonvarpskjarni-js-lib';
import { FetchPolicy } from '@apollo/client/core';
import WatchHistoryCarousel from '../components/WatchHistoryCarousel';
import PurchasesCarousel from '../components/PurchasesCarousel';
import ProductsCarousel from '../components/ProductsCarousel';
import { FrontpageAdsCarousel } from '../scenes/FrontpageScene/FrontpageAdCarousel';
import { FrontpageCategories } from '../scenes/FrontpageScene/FrontpageCategories';
import { SlugEventScene } from '../scenes/GenrePageScene/SlugEventScene';
import {
	CONTENT_LIMIT,
	LARGE_REGION_ID,
	SMALL_REGION_ID,
	SERVICES_REGION_ID,
	FRONTPAGE_REGIONID,
	SLUG_HOME,
	HERO_BANNER_CATEGORY,
	ENABLE_HERO_BANNER,
	CATEGORY_LIMIT,
	HERO_PLAYER_ITEM_LIMIT,
} from '../config/constants';
import { HeroBannerCarouselContainer } from '../components/HeroBanner/HeroBannerCarouselContainer';
import { HeaderGap } from '../components/HeaderGap/HeaderGap.styles';
import PageContent from '../models/strapi/PageContent';
import PageSectionComponent from '../components/PageSection/PageSectionComponent';
import PageMetaDataComponent from '../components/PageSection/PageMetaDataComponent';
import Globals from '../models/strapi/Globals';
import { getPageContent } from '../api/cms/getPageContent';

const frontpageProps = {
	largeRegionId: LARGE_REGION_ID,
	smallRegionId: SMALL_REGION_ID,
	servicesRegionId: SERVICES_REGION_ID,
	categoriesRegionId: FRONTPAGE_REGIONID,
	contentLimit: CONTENT_LIMIT,
};

const queryOptions = {
	fetchPolicy: 'no-cache' as FetchPolicy,
};

const FrontpageScene: React.FC<{ pageContentSsr?: any | null; globalContent?: Globals | null }> = ({ pageContentSsr }) => {
	const { small } = useFrontpage(frontpageProps);
	const { categories, fetchMoreCategories } = useContentFromSlug(SLUG_HOME, CONTENT_LIMIT, CATEGORY_LIMIT, true);
	const { category, heroBannerError } = useHeroBanner(HERO_BANNER_CATEGORY, HERO_PLAYER_ITEM_LIMIT, queryOptions);
	const pageContent = pageContentSsr !== null ? new PageContent(pageContentSsr) : null;

	return (
		<>
			<PageMetaDataComponent data={pageContent} />
			<div className={'frontpage-scene'}>
				{heroBannerError || !ENABLE_HERO_BANNER ? <HeaderGap /> : <HeroBannerCarouselContainer data={category} showInfoBtn />}
				<FrontpageAdsCarousel small={small} />
				<WatchHistoryCarousel />
				<SlugEventScene categories={categories} />
				<FrontpageCategories categories={categories} fetchMore={fetchMoreCategories} />
				<PurchasesCarousel />
				<ProductsCarousel productType={ProductType.PPV} />
				<PageSectionComponent data={pageContent} enforceLoggedOut />
			</div>
		</>
	);
};
export default FrontpageScene;
export const getServerSideProps = async (context) => {
	// Cache the response from strapi for one minute.
	context.res.setHeader('Cache-Control', 'public, s-maxage=60, stale-while-revalidate=3600');
	const pageContentSsr = await getPageContent('home');
	return {
		props: {
			pageContentSsr,
		},
	};
};
