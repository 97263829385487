import React from 'react';
import styled from 'styled-components';
import { PROGRESS_BACKGROUND, PROGRESS_FOREGROUND } from '../styles/colors';
import { Button, ButtonProps } from '../ui/core/Buttons/Buttons';

const ProgressButtonContainer = styled.div`
	position: relative;
	overflow: hidden;
	display: flex;
`;

const ButtonProgressContainer = styled.div`
	position: absolute;
	left: 0;
	bottom: 0;
	right: 0;
	background: ${PROGRESS_BACKGROUND};
	border-bottom-right-radius: 4px;
	border-bottom-left-radius: 4px;
`;

type ButtonProgressProps = {
	progress?: number;
};

const ButtonProgress = styled.div<ButtonProgressProps>`
	width: ${(props) => Math.round(props.progress * 100)}%;
	height: 4px;
	background: ${PROGRESS_FOREGROUND};
	border-bottom-right-radius: 4px;
	border-bottom-left-radius: 4px;
`;

export const ProgressButton: React.FC<ButtonProps & ButtonProgressProps> = ({ text, progress, handleClick, iconType, bgColor, bgHoverColor }) => {
	return (
		<ProgressButtonContainer>
			<Button text={text} handleClick={handleClick} iconType={iconType} bgColor={bgColor} bgHoverColor={bgHoverColor} />
			{progress !== undefined && (
				<ButtonProgressContainer>
					<ButtonProgress progress={progress} />
				</ButtonProgressContainer>
			)}
		</ProgressButtonContainer>
	);
};
