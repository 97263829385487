import Image from 'next/image';
import React, { FC } from 'react';
import { processImageUrl } from '../../../../utils/urls';
import { ContentTitle, HeroPlaceHolderImageContainer, ContentTitleContainer } from './HeroImageComponent.styles';

type Props = {
	imageUrl: string;
	title?: string;
	priority?: boolean;
};

export const HeroPlaceHolderImage: FC<Props> = ({ imageUrl, priority = false }) => (
	<HeroPlaceHolderImageContainer>{imageUrl && <Image priority={priority} layout="fill" objectFit="cover" src={processImageUrl(imageUrl)} alt="mobile image" />}</HeroPlaceHolderImageContainer>
);

export const HeroIcon: FC<Props> = ({ imageUrl, title }) => {
	return (
		<>
			{imageUrl ? (
				<HeroPlaceHolderImageContainer>
					<Image priority layout="fill" objectFit="cover" src={processImageUrl(imageUrl)} alt="shows logo" />
				</HeroPlaceHolderImageContainer>
			) : (
				<ContentTitleContainer>
					<ContentTitle>{title}</ContentTitle>
				</ContentTitleContainer>
			)}
		</>
	);
};
