import styled from 'styled-components';
import { media } from '../../ui/utils/media';

export const ContentButtonsContainer = styled.div`
	display: flex;
	justify-content: center;
	gap: 8px;
	flex-wrap: wrap;
	width: fit-content;
	${media.tabletLandscapeUp} {
		justify-content: flex-start;
	}
	${media.desktopUp} {
		gap: 16px;
	}
	${media.desktopXLargeUp} {
		gap: 21.33px;
	}
`;

export const ContentButtonsMessage = styled.div`
	font-size: 20px;
	font-weight: bold;
	line-height: 1.2;
`;
