import React, { FC } from 'react';
import { Category } from '@vodafoneis/sjonvarpskjarni-js-lib';
import { HeroBannerContainer, HeroBannerFullWidthWrapper } from '../LightPlayer/Layout/HeroLayout/HeroLayout.styles';
import { HeroBannerCarousel } from './HeroBannerCarousel';

export const HeroBannerCarouselContainer: FC<{ data: Category; showInfoBtn: boolean }> = ({ showInfoBtn, data }) => {
	return (
		<HeroBannerContainer>
			<HeroBannerFullWidthWrapper>{data?.content?.length ? <HeroBannerCarousel withInfoBtn={showInfoBtn} label={data.title} slides={data.content} /> : null}</HeroBannerFullWidthWrapper>
		</HeroBannerContainer>
	);
};
