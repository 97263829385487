import styled, { css } from 'styled-components';

const reactPlayer = css`
	.react-player video {
		object-fit: fill;
		object-position: center;
	}
`;
export const PlayerContainer = styled.div`
	display: flex;
	height: 100%;
	width: 100%;
	${reactPlayer};
`;
