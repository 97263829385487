import styled from 'styled-components';
import { H1 } from '../../../../ui/core/Typography/typography.styles';
import { media } from '../../../../ui/utils/media';

export const HeroPlaceHolderImageContainer = styled.div`
	display: flex;
	position: relative;
	height: 100%;
	width: 100%;
`;

export const ContentTitleContainer = styled.div`
	display: flex;
	height: 100%;
	align-items: flex-end;
	justify-content: center;
	${media.tabletLandscapeUp} {
		justify-content: flex-start;
	}
`;

export const ContentTitle = styled(H1)`
	text-align: center;
	${media.tabletLandscapeUp} {
		text-align: left;
	}
	&::first-letter {
		text-transform: capitalize;
	}
`;
