import styled, { css } from 'styled-components';
import { media } from '../../ui/utils/media';

const absoluteHeights = css`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	height: 640px;
	${media.tabletPortraitUp} {
		height: 640px;
	}
	${media.tabletLandscapeUp} {
		height: 768px;
	}
	${media.desktopUp} {
		height: 1024px;
	}
	${media.desktopLargeUp} {
		height: 1080px;
	}
	${media.desktopXLargeUp} {
		height: 1440px;
	}
`;

export const HeroPlayerWrapper = styled.div`
	width: 100%;
	${absoluteHeights};
`;
export const HeroLayoutContainer = styled.div`
	display: flex;
	flex: 1;
	height: 617px;
	${media.tabletPortraitUp} {
		height: 627px;
	}
	${media.tabletLandscapeUp} {
		height: 687px;
	}
	${media.desktopUp} {
		height: 949px;
	}
	${media.desktopLargeUp} {
		height: 960px;
	}
	${media.desktopXLargeUp} {
		height: 1280px;
	}
`;

export const Slide = styled.div`
	flex: 0 0 100%;
`;

export const Dots = styled.div`
	display: flex;
	list-style: none;
	justify-content: center;
	position: absolute;
	width: 100%;
	bottom: 40px;
	${media.tabletPortraitUp} {
		bottom: 35px;
	}
	${media.tabletLandscapeUp} {
		bottom: 120px;
	}
	${media.desktopUp} {
		bottom: 150px;
	}
	${media.desktopLargeUp} {
		bottom: 200px;
	}
	${media.desktopXLargeUp} {
		bottom: 264px;
	}
`;

export const ViewPort = styled.div`
	overflow: hidden;
	height: 100%;
`;

export const ViewPortContent = styled.div`
	display: flex;
	height: 100%;
	user-select: none;
	-webkit-touch-callout: none;
	-khtml-user-select: none;
	-webkit-tap-highlight-color: transparent;
	will-change: transform;
`;
