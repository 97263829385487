import React, { FC } from 'react';
import Image, { StaticImageData } from 'next/image';
import { ImageContainer } from './AgeRatingLabel.styles';
import all from '../../../public/images/0@1.5x.png';
import six from '../../../public/images/6@1.5x.png';
import nine from '../../../public/images/9@1.5x.png';
import twelve from '../../../public/images/12@1.5x.png';
import sixteen from '../../../public/images/16@1.5x.png';
import eighteen from '../../../public/images/18@1.5x.png';

type Age = 0 | 6 | 9 | 12 | 16 | 18;

type AgeRatingMapperProps = Record<Age, StaticImageData>;

const ageRatingMapper: AgeRatingMapperProps = {
	0: all,
	6: six,
	9: nine,
	12: twelve,
	16: sixteen,
	18: eighteen,
};
type Props = {
	age: number;
};

export const AgeRatingLabel: FC<Props> = ({ age }) => {
	const backgroundImage = ageRatingMapper[age] ?? null;
	if (!backgroundImage) return null;
	return (
		<ImageContainer>
			<Image src={backgroundImage} height={24} width={24} />
		</ImageContainer>
	);
};
