import styled, { css, keyframes } from 'styled-components';
import { IntroText, sharedFontStyles } from '../../../../ui/core/Typography/typography.styles';
import { media } from '../../../../ui/utils/media';

const absoluteHeights = css`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	height: 640px;
	${media.tabletPortraitUp} {
		height: 640px;
	}
	${media.tabletLandscapeUp} {
		height: 768px;
	}
	${media.desktopUp} {
		height: 1024px;
	}
	${media.desktopLargeUp} {
		height: 1080px;
	}
	${media.desktopXLargeUp} {
		height: 1440px;
	}
`;

const relativeHeight = css`
	height: 617px;
	${media.tabletPortraitUp} {
		height: 627px;
	}
	${media.tabletLandscapeUp} {
		height: 687px;
	}
	${media.desktopUp} {
		height: 949px;
	}
	${media.desktopLargeUp} {
		height: 960px;
	}
	${media.desktopXLargeUp} {
		height: 1280px;
	}
`;

export const HeroBannerContainer = styled.div`
	display: flex;
	flex: 1;
	${relativeHeight};
`;

export const HeroBannerFullWidthWrapper = styled.div`
	${absoluteHeights};
`;

export const HeroPlayerContainer = styled.div`
	height: 100%;
	width: 100%;
	position: relative;
`;

export const PlayerShadesShades = styled.div`
	${absoluteHeights};
	background: linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, #000000 100%);
	${media.tabletLandscapeUp} {
		background: linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, #000000 100%), linear-gradient(90deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 50%);
	}
	pointer-events: none;
`;

export const LayoverContainer = styled.div`
	position: absolute;
	display: flex;
	width: 100%;
	${relativeHeight};
	z-index: 2;
	flex-direction: column;
	left: 0;
	padding-left: ${({ theme }) => theme.screenLayoutGutters.mobile.left}px;
	padding-right: ${({ theme }) => theme.screenLayoutGutters.mobile.right}px;
	${media.tabletPortraitUp} {
		padding-left: ${({ theme }) => theme.screenLayoutGutters.tabletPortrait.left}px;
		padding-right: ${({ theme }) => theme.screenLayoutGutters.tabletPortrait.right}px;
	}
	${media.tabletLandscapeUp} {
		padding-left: ${({ theme }) => theme.screenLayoutGutters.tabletLandscape.left}px;
		padding-right: ${({ theme }) => theme.screenLayoutGutters.tabletLandscape.right}px;
	}
	${media.desktopUp} {
		padding-left: ${({ theme }) => theme.screenLayoutGutters.desktop.left}px;
		padding-right: ${({ theme }) => theme.screenLayoutGutters.desktop.right}px;
	}
	${media.desktopLargeUp} {
		padding-left: ${({ theme }) => theme.screenLayoutGutters.desktopLarge.left}px;
		padding-right: ${({ theme }) => theme.screenLayoutGutters.desktopLarge.right}px;
	}
	${media.desktopXLargeUp} {
		padding-left: ${({ theme }) => theme.screenLayoutGutters.desktopXLarge.left}vw;
		padding-right: ${({ theme }) => theme.screenLayoutGutters.desktopXLarge.right}vw;
	}
`;

export const HeroLabelBottom = styled.div`
	${sharedFontStyles};
	text-transform: uppercase;

	display: flex;
	margin-bottom: 18.6px;
	font-weight: 800;
	font-size: 11.3778px;
	line-height: 14px;
	${media.desktopUp} {
		margin-bottom: 26.5px;
		font-size: 16px;
		line-height: 19px;
	}
	${media.desktopXLargeUp} {
		margin-bottom: 35px;
		font-size: 21.3333px;
		line-height: 26px;
	}
`;

export const HeroContent = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	width: 100%;
	height: 100%;
	flex-direction: column;
	padding-bottom: 63px;
	${media.tabletPortraitUp} {
		padding-bottom: 73px;
	}
	${media.tabletLandscapeUp} {
		align-items: flex-start;
		padding-bottom: 107px;
	}
	${media.desktopUp} {
		padding-bottom: 143px;
	}
	${media.desktopLargeUp} {
		padding-bottom: 170px;
	}
	${media.desktopXLargeUp} {
		padding-bottom: 226px;
	}
`;

export const ContentLogo = styled.div`
	height: 160.25px;
	width: 256px;
	margin-bottom: 10.75px;
	${media.tabletLandscapeUp} {
		height: 202.67px;
		width: 322.84px;
		margin-bottom: 30px;
	}
	${media.desktopUp} {
		height: 285px;
		width: 454px;
		margin-bottom: 42.5px;
	}
	${media.desktopXLargeUp} {
		height: 380px;
		width: 605.33px;
		margin-bottom: 56.33px;
	}
`;
export const ContentMetaDataContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 12px;
	margin-bottom: 26px;
	flex-wrap: wrap;
	${media.tabletLandscapeUp} {
		margin-bottom: 28px;
		gap: 8.5px;
	}
	${media.desktopUp} {
		margin-bottom: 56px;
		gap: 12px;
	}
	${media.desktopXLargeUp} {
		margin-bottom: 74px;
		gap: 16px;
	}
`;

// for now it seems this style is not repeated in the design
// if it is this should be moved to typography.styles
export const ContentMetaData = styled.p`
	all: unset;
	${sharedFontStyles};
	font-weight: 600;
	font-size: 12px;
	line-height: 14px;
	color: rgba(255, 255, 255, 0.6);
	${media.desktopUp} {
		font-size: 14px;
		line-height: 17px;
	}
	${media.desktopXLargeUp} {
		font-size: 18.6px;
		line-height: 22px;
	}
	&::first-letter {
		text-transform: capitalize;
	}
`;

export const ContentMetaDataCapitalized = styled(ContentMetaData)`
	text-transform: capitalize;
`;

export const ContentControlsContainer = styled.div`
	display: flex;
	justify-content: center;
	${media.tabletLandscapeUp} {
		justify-content: space-between;
	}
	width: 100%;
`;

export const ContentControls = styled.div`
	display: flex;
	justify-content: center;
	gap: 8px;
	${media.tabletLandscapeUp} {
		justify-content: flex-start;
	}
	${media.desktopUp} {
		gap: 16px;
	}
	${media.desktopXLargeUp} {
		gap: 21.33px;
	}
`;
export const ContentDescription = styled(IntroText)`
	width: 368px;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
	${media.tabletPortrait} {
		display: none;
	}
	${media.tabletLandscapeUp} {
		margin-bottom: 17px;
	}
	${media.desktopUp} {
		width: 443.7px;
		margin-bottom: 24px;
	}
	${media.desktopLargeUp} {
		width: 592px;
	}
	${media.desktopXLargeUp} {
		margin-bottom: 32px;
		width: 789px;
	}
	&::first-letter {
		text-transform: capitalize;
	}
`;

export const FadeInOutContainer = styled.div<{ hide }>`
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	opacity: ${({ hide }) => (hide ? 0 : 1)};
	transition: opacity 2s linear;
`;

const fadeInAnimation = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

export const FadeInContainer = styled.div`
	animation-name: ${fadeInAnimation};
	animation-duration: 2s;
	animation-fill-mode: forwards;
	animation-delay: 1s;
	opacity: 0;
`;
