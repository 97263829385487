import React, { FC, useEffect, useState } from 'react';
import { useWindowSize } from 'react-use';
import { useTheme } from 'styled-components';
import { Content, Playable, useFavorite } from '@vodafoneis/sjonvarpskjarni-js-lib';
import { useRouter } from 'next/router';
import { HeroPlayer } from '../../Player/Player';
import {
	LayoverContainer,
	HeroContent,
	ContentLogo,
	ContentMetaDataContainer,
	ContentControlsContainer,
	ContentControls,
	ContentDescription,
	ContentMetaData,
	HeroLabelBottom,
	HeroPlayerContainer,
	PlayerShadesShades,
	FadeInOutContainer,
	FadeInContainer,
} from './HeroLayout.styles';
import { ICON_TYPE } from '../../../IconComponent/IconComponent';
import { Hidden } from '../../../../ui/core/Hidden/hidden';
import { AgeRatingLabel } from '../../../AgeRatingLabel/AgeRatingLabel';
import { HeroIcon, HeroPlaceHolderImage } from './HeroImageComponent';
import { ActionBtn } from '../../../../ui/core/Buttons/Buttons';
import { ContentButtons } from '../../../ContentButtons/ContentButtons';
import { HeroBannerSeasonInfo } from './SeasonInfo';
import { Genres } from './Genres';

type Props = {
	title: string;
	logoUrl?: string;
	description?: string;
	label?: string;
	posterUrl?: string;
	mobilePosterUrl?: string;
	trailerUrl?: string;
	metadata?: Metadata;
	isInView: boolean;
	content: Content;
	playable: Playable;
	withFavBtn: boolean;
	withInfoBtn: boolean;
	infoRoute?: string;
};

type Metadata = {
	ageRating?: number;
	seasonInfo?: SeasonInfoProps;
	genres?: GenresProps[];
	year?: number;
};

type GenresProps = {
	title?: string;
};

type SeasonInfoProps = {
	episodeCount?: number;
	seasonCount: number;
};

export const HeroLayout: FC<Props> = ({
	content,
	playable,
	isInView,
	logoUrl,
	description,
	label,
	title,
	posterUrl,
	mobilePosterUrl,
	trailerUrl,
	withFavBtn,
	withInfoBtn,
	metadata: { ageRating, seasonInfo, genres, year },
	infoRoute,
}) => {
	const [muted, toggleMute] = useState(true);
	const { width } = useWindowSize();
	const { screenLayout } = useTheme();
	const [hidePlayer, setHidePlayer] = useState<boolean>(() => {
		return width <= screenLayout.tabletPortrait;
	});
	const router = useRouter();
	const [isPlaying, setIsPlaying] = useState<boolean>(false);
	const [playerReady, setPlayerReady] = useState<boolean>(false);
	const { favorite, toggleFavorite } = useFavorite(content);

	useEffect(() => {
		if (width < screenLayout.tabletLandscape && !hidePlayer) {
			setHidePlayer(true);
			setIsPlaying(false);
		}
		if (width >= screenLayout.tabletLandscape && hidePlayer) {
			setHidePlayer(false);
		}
	}, [width, hidePlayer, screenLayout]);

	const handleClick = () => {
		if (infoRoute && withInfoBtn) {
			router.push(infoRoute);
		}
	};

	const onPause = () => {
		setIsPlaying(false);
	};

	const onPlay = () => {
		setIsPlaying(true);
	};

	const onReady = () => {
		setPlayerReady(true);
	};

	return (
		<HeroPlayerContainer>
			<LayoverContainer>
				<HeroContent>
					<ContentLogo>
						<HeroIcon imageUrl={logoUrl} title={title} />
					</ContentLogo>
					<HeroLabelBottom>{label ?? ''}</HeroLabelBottom>
					<ContentDescription>{description ?? ''}</ContentDescription>
					<ContentMetaDataContainer>
						<AgeRatingLabel age={ageRating} />
						{seasonInfo && <HeroBannerSeasonInfo seasonCount={seasonInfo?.seasonCount} />}
						{!!genres?.length && <Genres genres={genres} />}
						{year && <ContentMetaData>{year}</ContentMetaData>}
					</ContentMetaDataContainer>
					<ContentControlsContainer>
						<ContentControls>
							<ContentButtons simpleView content={playable} playable={playable} />
							{withInfoBtn && <ActionBtn type={ICON_TYPE.INFO} handleClick={handleClick} />}
							{withFavBtn && <ActionBtn type={favorite ? ICON_TYPE.REMOVE : ICON_TYPE.ADD} handleClick={toggleFavorite} />}
						</ContentControls>
						<Hidden below="tabletLandscape">
							{isInView && playerReady && isPlaying && (
								<FadeInContainer>
									<ActionBtn type={muted ? ICON_TYPE.SOUND_OFF : ICON_TYPE.SOUND_ON} handleClick={() => toggleMute(!muted)} />
								</FadeInContainer>
							)}
						</Hidden>
					</ContentControlsContainer>
				</HeroContent>
			</LayoverContainer>
			<FadeInOutContainer hide={isPlaying}>
				<HeroPlaceHolderImage priority={isInView} imageUrl={posterUrl} />
			</FadeInOutContainer>
			{hidePlayer ? (
				<HeroPlaceHolderImage priority={isInView} imageUrl={mobilePosterUrl} />
			) : (
				<HeroPlayer
					handleOnReady={onReady}
					handleOnPause={onPause}
					handleOnPlay={onPlay}
					playing={playerReady && isInView}
					ErrorComponent={<HeroPlaceHolderImage imageUrl={posterUrl} />}
					trailerUrl={trailerUrl}
					muted={muted}
				/>
			)}
			<PlayerShadesShades />
		</HeroPlayerContainer>
	);
};
