import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { usePrevious } from 'react-use';
import { useRouter } from 'next/router';
import { FetchPolicy } from '@apollo/client';
import { Episode, Series, useContent, useNextEpisode, useEpisodes } from '@vodafoneis/sjonvarpskjarni-js-lib';
import { ACTORS_LIMIT, SIMILAR_CONTENT_LIMIT } from '../config/constants';
import MoviePlaylistItem from '../models/MoviePlaylistItem';
import { PlaybackContext } from './PlaybackContext';

export type SeriesSceneContextProps = {
	series: Series;
	episodes: Episode[];
	nextEpisode?: Episode;
	selectedSeason: number;
	setSelectedSeason: (selectedSeasonNumber: number) => void;
	playEpisode: (episode: Episode, position?: number) => void;
};

const contentQueryOptions = {
	fetchPolicy: 'network-only' as FetchPolicy,
	nextFetchPolicy: 'cache-first' as FetchPolicy,
};

const episodesQueryOptions = {
	fetchPolicy: 'cache-and-network' as FetchPolicy,
};

export const SeriesSceneContext = createContext<Partial<SeriesSceneContextProps>>({});

export const SeriesSceneContextProvider = ({ seriesId, children }) => {
	const previousSeriesId = usePrevious(seriesId);
	const [selectedSeason, setSelectedSeason] = useState<number>(null);
	const router = useRouter();
	const { startPlaylist, setInitialPlaybackPosition } = useContext(PlaybackContext);

	const contentProps = useContent(seriesId, SIMILAR_CONTENT_LIMIT, ACTORS_LIMIT, contentQueryOptions);

	const { loading, content } = contentProps;

	// @ts-ignore
	const series = content as Series;

	const { episodes } = useEpisodes(series?.id, selectedSeason, episodesQueryOptions);

	useEffect(() => {
		if (seriesId !== previousSeriesId) {
			// Reset selected season when series changes.
			setSelectedSeason(null);
		} else if (selectedSeason === null && !loading) {
			// selectedSeason can be 0
			if (series?.nextEpisode) {
				setSelectedSeason(series.nextEpisode.episodeSeason);
			} else if (series?.seasons?.length > 0) {
				setSelectedSeason(series.seasons[0].number);
			}
		}
	}, [content, loading, previousSeriesId, selectedSeason, series, seriesId]);

	const nextEpisode = useNextEpisode(series, episodes);

	const playEpisode = useCallback(
		(episode: Episode, position?: number) => {
			if (episode.isTVOD() || (episode.isSubscription() && !episode.getWatchableLicense())) {
				router.push(`/movie/${episode.id}`);
			} else {
				let playlist = [...episodes];

				if (playlist.find((ep) => ep.isCatchup())) {
					playlist = playlist.reverse();
				}

				const idx = playlist.findIndex((ep) => ep.id === episode.id);

				playlist = playlist.slice(idx);

				const playlistItems = playlist.map((ep) => new MoviePlaylistItem(ep));

				startPlaylist(playlistItems);

				if (position !== undefined) {
					setInitialPlaybackPosition(position);
				}

				router.push(`/series/${episode.series.id}/play`);
			}
		},
		[episodes, router, setInitialPlaybackPosition, startPlaylist]
	);

	const value = useMemo(() => {
		return {
			series,
			episodes,
			nextEpisode,
			selectedSeason,
			setSelectedSeason,
			playEpisode,
		};
	}, [series, episodes, nextEpisode, selectedSeason, playEpisode]);

	return <SeriesSceneContext.Provider value={value}>{children}</SeriesSceneContext.Provider>;
};
