import React from 'react';
import { useTranslation } from 'react-i18next';
import { FetchPolicy } from '@apollo/client';
import { ModuleType, usePurchases } from '@vodafoneis/sjonvarpskjarni-js-lib';
import MediaCarousel from './MediaCarousel';
import useContentGridRenderer from '../hooks/useContentGridRenderer';
import { CONTENT_LIMIT } from '../config/constants';
import { MediaComponentType } from '../models/MediaComponentType';

const { PURCHASE } = MediaComponentType;
const { M4 } = ModuleType;

const queryOptions = {
	fetchPolicy: 'cache-and-network' as FetchPolicy,
};

export default function PurchasesCarousel() {
	const { t } = useTranslation();

	const renderItem = useContentGridRenderer({ moduleType: M4, mediaComponentType: PURCHASE });

	const { purchases, fetchMorePurchases } = usePurchases(CONTENT_LIMIT, queryOptions);

	if (purchases?.length > 0) {
		return (
			<>
				<MediaCarousel
					id={'carousel-purchases'}
					title={t('MyContent.PurchasedContent')}
					items={purchases}
					detailsUrl={'/purchases'}
					renderItem={renderItem}
					fetchMoreItems={fetchMorePurchases}
				/>
			</>
		);
	}
	return null;
}
