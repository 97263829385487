import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ContentMetaData } from './HeroLayout.styles';

type SeasonInfoProps = {
	seasonCount: number;
	episodeCount?: number;
};

export const HeroBannerSeasonInfo: FC<SeasonInfoProps> = ({ seasonCount }) => {
	const { t } = useTranslation();
	const seasonText = seasonCount >= 2 ? t('MediaComponent.Subtitle.Seasons', { count: seasonCount }) : t('MediaComponent.Subtitle.Season', { count: seasonCount });

	return <ContentMetaData>{seasonText}</ContentMetaData>;
};
export const SeasonInfo: FC<SeasonInfoProps> = ({ seasonCount, episodeCount }) => {
	const { t } = useTranslation();
	const seasonText = seasonCount >= 2 ? t('MediaComponent.Subtitle.Seasons', { count: seasonCount }) : t('MediaComponent.Subtitle.Season', { count: seasonCount });
	const episodeText = episodeCount >= 2 ? t('MediaComponent.Subtitle.Episodes_other', { count: episodeCount }) : t('MediaComponent.Subtitle.Episodes', { count: episodeCount });

	return <ContentMetaData>{`${seasonText} (${episodeText})`}</ContentMetaData>;
};
