import React, { useCallback, useMemo } from 'react';
import chunk from 'lodash/chunk';
import { Ad, ModuleType } from '@vodafoneis/sjonvarpskjarni-js-lib';
import styled from 'styled-components';
import MediaCarousel from '../../components/MediaCarousel';
import { breakpoints } from '../../styles/breakpoints';
import { MediaComponent } from '../../components/MediaComponent';
import { MediaComponentType } from '../../models/MediaComponentType';
import { CarouselColumn } from '../../components/CarouselColumn';

const { AD } = MediaComponentType;
const { M2, M4 } = ModuleType;

const RowNoWrap = styled.div`
	flex-wrap: nowrap;

	@media ${breakpoints.lg} {
		flex-wrap: wrap;
	}
`;

const MediaComponentSmall = styled(MediaComponent)`
	@media ${breakpoints.lg} {
		padding-bottom: 55.5%;
	}
`;

type FrontpageAdsCarouselProps = {
	large?: Ad[];
	small?: Ad[];
};

export const FrontpageAdsCarousel: React.FC<FrontpageAdsCarouselProps> = ({ large, small }) => {
	const items = useMemo(() => {
		if (!large || !small) return null;

		const smallChunks = chunk(small, 4);

		return large.reduce((ret, largeAd, idx) => {
			if (smallChunks[idx] && smallChunks[idx].length === 4) {
				return [...ret, largeAd, smallChunks[idx]];
			}

			return [...ret, largeAd];
		}, []);
	}, [large, small]);

	const renderItem = useCallback((item, clickEnabled) => {
		if (Array.isArray(item)) {
			return (
				<CarouselColumn key={item[0].id} xs={12} md={6}>
					<RowNoWrap className={'row'}>
						{item.map((ad) => {
							return (
								<CarouselColumn key={ad.id} xs={12} lg={6}>
									<MediaComponentSmall item={ad} size={M4} type={AD} enabled={clickEnabled} />
								</CarouselColumn>
							);
						})}
					</RowNoWrap>
				</CarouselColumn>
			);
		}

		return (
			<CarouselColumn key={item.id} xs={12} md={6}>
				<MediaComponent item={item} size={M2} type={AD} enabled={clickEnabled} />
			</CarouselColumn>
		);
	}, []);

	return <MediaCarousel id={'carousel-frontpage-ads'} items={items} renderItem={renderItem} />;
};
