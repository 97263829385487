import React, { useState, useEffect, useCallback, FC, useContext, useMemo } from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import { Content, Movie, Series, useContent } from '@vodafoneis/sjonvarpskjarni-js-lib';
import { FetchPolicy } from '@apollo/client';
import { UserContext } from '../../contexts/UserContext';
import { EmblaDotButton, PrevButton, NextButton } from './HeroBannerButtons';
import { HeroLayout } from '../LightPlayer/Layout/HeroLayout/HeroLayout';
import { Dots, Slide, ViewPort, ViewPortContent } from './HeroBanner.styles';
import PlaylistItem from '../../models/PlaylistItem';
import { Icon } from '../IconComponent/IconComponent';
import { SeriesSceneContext, SeriesSceneContextProvider } from '../../contexts/SeriesSceneContext';
import { ENABLE_HERO_TRAILERS } from '../../config/constants';

type Props = {
	slides: Content[];
	label: string;
	withInfoBtn?: boolean;
	withFavBtn?: boolean;
};

const queryOptions = {
	fetchPolicy: 'cache-first' as FetchPolicy,
};

export const HeroBannerCarousel: FC<Props> = ({ slides, label, withInfoBtn, withFavBtn }) => {
	const [viewportRef, embla] = useEmblaCarousel({ skipSnaps: false, inViewThreshold: 100 });
	const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
	const [nextBtnEnabled, setNextBtnEnabled] = useState(false);
	const [selectedIndex, setSelectedIndex] = useState(0);
	const [scrollSnaps, setScrollSnaps] = useState([]);
	const { isLoggedIn } = useContext(UserContext);
	const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);
	const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);
	const scrollTo = useCallback((index) => embla && embla.scrollTo(index), [embla]);

	const onSelect = useCallback(() => {
		if (embla) {
			setSelectedIndex(embla.selectedScrollSnap());
			setPrevBtnEnabled(embla.canScrollPrev());
			setNextBtnEnabled(embla.canScrollNext());
		}
	}, [embla, setSelectedIndex]);

	useEffect(() => {
		if (embla) {
			setScrollSnaps(embla.scrollSnapList());
			embla.on('select', onSelect);
			onSelect();
		}
	}, [embla, setScrollSnaps, onSelect]);

	return (
		<>
			<ViewPort ref={viewportRef}>
				<ViewPortContent>
					{slides.map((item, index) => (
						<Slide key={`${item.id}`}>
							<>
								{item.isSeries() && (
									<SeriesSceneContextProvider seriesId={Number(item.id)}>
										<SeriesSlide withFavBtn={isLoggedIn} withInfoBtn={withInfoBtn} label={label} index={index} selectedIndex={selectedIndex} item={item} />
									</SeriesSceneContextProvider>
								)}
								{item.isMovie() && <MovieSlide withInfoBtn={withInfoBtn} label={label} index={index} selectedIndex={selectedIndex} item={item} />}
							</>
						</Slide>
					))}
				</ViewPortContent>
			</ViewPort>
			<PrevButton onClick={scrollPrev} disabled={!prevBtnEnabled}>
				<Icon type="ARROW_RIGHT_L" />
			</PrevButton>
			<NextButton onClick={scrollNext} disabled={!nextBtnEnabled}>
				<Icon type="ARROW_RIGHT_L" />
			</NextButton>
			<Dots>
				{scrollSnaps.map((_, index) => (
					// eslint-disable-next-line react/no-array-index-key
					<EmblaDotButton key={`${index}`} selected={index === selectedIndex} onClick={() => scrollTo(index)} />
				))}
			</Dots>
		</>
	);
};

type SlideGeneratorProps = {
	item: Content;
	index: number;
	selectedIndex: number;
	label: string;
	withFavBtn?: boolean;
	withInfoBtn?: boolean;
};

const MovieSlide: FC<SlideGeneratorProps> = ({ item, index, selectedIndex, label, withInfoBtn, withFavBtn }) => {
	const { content, loading } = useContent(item.id, 0, 0, queryOptions);
	const movie = new Movie(content);

	return (
		<>
			{content && !loading ? (
				<HeroLayout
					content={movie}
					withFavBtn={false}
					withInfoBtn={withInfoBtn}
					playable={movie}
					title={movie.title}
					description={movie?.shortDescription ? movie?.shortDescription : movie?.description}
					label={label}
					posterUrl={movie.getBackdropImageUrl()}
					trailerUrl={ENABLE_HERO_TRAILERS ? PlaylistItem._prefixUrl(movie.trailer?.dash) : null}
					metadata={{ genres: movie.genres, ageRating: content.ageRating }}
					isInView={index === selectedIndex}
					mobilePosterUrl={movie.getPosterImageUrl()}
					infoRoute={`/movie/${movie.id}`}
				/>
			) : null}
		</>
	);
};

const SeriesSlide: FC<SlideGeneratorProps> = ({ index, selectedIndex, label, withInfoBtn, item, withFavBtn }) => {
	const { series, nextEpisode } = useContext(SeriesSceneContext);

	return (
		<>
			{series ? (
				<HeroLayout
					content={series}
					withFavBtn={withFavBtn}
					withInfoBtn={withInfoBtn}
					playable={nextEpisode}
					title={series.title}
					description={series?.shortDescription ? series?.shortDescription : series?.description}
					label={label}
					posterUrl={series.getBackdropImageUrl()}
					trailerUrl={ENABLE_HERO_TRAILERS ? PlaylistItem._prefixUrl(series.trailer?.dash) : null}
					metadata={{
						genres: series.genres,
						seasonInfo: { seasonCount: series.seasons.length },
						ageRating: series.ageRating,
					}}
					isInView={index === selectedIndex}
					mobilePosterUrl={series.getPosterImageUrl()}
					infoRoute={`/series/${series.id}`}
				/>
			) : null}
		</>
	);
};
