import React, { FC } from 'react';
import { ContentMetaData } from './HeroLayout.styles';

type Props = {
	genres: ContentProps[];
};

type ContentProps = {
	title?: string;
};

export const Genres: FC<Props> = ({ genres }) => {
	const tags = genres.slice(0, 3).reduce((acc, curr) => {
		if (!curr.title) {
			return acc;
		}
		if (acc.length <= 1) {
			return `${acc} ${curr?.title} `;
		}
		return `${acc} / ${curr?.title} `;
	}, '');

	return <ContentMetaData>{tags}</ContentMetaData>;
};
