import React, { FC, ReactElement, useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player/lazy';
import { PlayerContainer } from './Player.styles';
import { Client as Bugsnag } from '../../../utils/Bugsnag';

type Props = {
	trailerUrl: string;
	posterUrl?: string;
	muted: boolean;
	ErrorComponent: ReactElement;
	playing: boolean;
	handleOnPlay: () => void;
	handleOnPause: () => void;
	handleOnReady: () => void;
};

export const HeroPlayer: FC<Props> = ({ trailerUrl, muted, ErrorComponent, playing, handleOnPause, handleOnPlay, handleOnReady }) => {
	const ref = useRef<ReactPlayer>(null);
	const [canPlay, setCanPlay] = useState<boolean>(false);

	const handleError = (error) => {
		// player will revert to using the poster instead so this is only for logging
		Bugsnag.notify(error);
	};

	useEffect(() => {
		setCanPlay(ReactPlayer.canPlay(trailerUrl));
	}, [trailerUrl]);
	return (
		<PlayerContainer>
			{!canPlay ? (
				<>{ErrorComponent}</>
			) : (
				<ReactPlayer
					fallback={ErrorComponent}
					onPlay={handleOnPlay}
					onPause={handleOnPause}
					className="react-player"
					playing={playing}
					muted={muted}
					onError={handleError}
					ref={ref}
					height="100%"
					width="100%"
					url={trailerUrl}
					onReady={handleOnReady}
					volume={1}
				/>
			)}
		</PlayerContainer>
	);
};
